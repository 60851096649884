import Repo from '@/apiv2/Repo';
import { reactive } from 'vue';
import {
  ConsentI,
  ConsentStateI,
  ReferenceI,
  ShareType
} from '@/composables/types/consent';
import usePatient from '@/composables/usePatient';
import useNotification from '@/composables/useNotification';
import { NotificationType } from '@/composables/types/notification';
import i18n from '@/lang';
import useUser from '@/composables/useUser';
import cloneDeep from 'lodash/cloneDeep';
import { formatDate, ok } from '@fhir/pixel-commons-js/src/index';
import formatISO from 'date-fns/formatISO';
import { SharedResources, Resources } from './types/resources';
import jp, { value } from 'jsonpath';
import { DateFilter } from '@/store/search/types';
import { createDateParams } from '@/utils/utils';

const getPatientFromResource = async (
  resource: any
): Promise<ReferenceI | undefined> => {
  if (resource != null) {
    const patient = (resource.subject || resource.patient)?.reference;
    const tenant = jp.value(
      resource,
      "$.meta.tag[?(@.system=='urn:tenant')].code"
    );
    const patientData = await usePatient().loadPatientByReference(
      tenant,
      patient
    );
    if (patientData) {
      return {
        reference: patient,
        identifier: {
          system: patientData?.pesel?.system,
          value: patientData?.pesel?.value
        },
        display: `${patientData.name || ''} ${patientData.birthDate ||
          ''} ${patientData?.pesel?.value || ''}`
      };
    }
  }
  return undefined;
};

const makeCreateConsentFromResource = async (
  shareType: ShareType,
  shareValue: string | number,
  resource: any
): Promise<ConsentI> => {
  const patient = await getPatientFromResource(resource);
  const userName = useUser().getUserName() || '';
  return {
    resourceType: 'Consent',
    status: 'active',
    patient: patient,
    dateTime: formatISO(new Date()), // TODO
    consentor: {
      display: userName
    },
    provision: {
      type: 'permit', // TODO
      period: {
        start: formatDate(new Date(), 'yyyy-MM-dd'), // TODO
        end: ''
      },
      actor: [
        {
          reference: {
            identifier: {
              system: shareType.toString(),
              value: shareValue.toString()
            }
          }
        }
      ],
      data: [
        {
          meaning: 'instance',
          reference: {
            reference: `${resource.resourceType}/${resource?.id}`
          }
        }
      ]
    }
  };
};

const makeConsentByLink = async (resource: any): Promise<ConsentI> => {
  const patient = await getPatientFromResource(resource);
  const userName = useUser().getUserName() || '';
  return {
    resourceType: 'Consent',
    status: 'active',
    patient: patient,
    dateTime: formatISO(new Date()),
    consentor: {
      display: userName
    },
    provision: {
      type: 'permit', // TODO
      period: {
        start: formatDate(new Date(), 'yyyy-MM-dd'), // TODO
        end: ''
      },
      data: [
        {
          meaning: 'instance',
          reference: {
            reference: `${resource.resourceType}/${resource?.id}`
          }
        }
      ]
    }
  };
};

const state: ConsentStateI = reactive({
  consentList: [],
  consentListSharedFourUser: []
});

async function loadMultipleResourcesBySettled(
  response: any,
  dateFilter?: DateFilter,
  patientFilter?: string
) {
  let entryResources = [];

  // check if response is array or single resource
  if (response?.data?.entry) entryResources = response.data.entry;
  if (response?.resourceType) entryResources = [{ resource: response }];
  entryResources = entryResources.filter(
    (f: any) => f?.resource?.status === 'active'
  );

  if (entryResources) {
    let Promises: any = [];
    const patientData: any = {};
    const consentorData: any = {};

    entryResources.forEach((entry: any) => {
      const provisionData = entry?.resource?.provision?.data;
      if (provisionData) {
        provisionData.forEach((prov: any) => {
          const ref = prov?.reference?.reference
            ?.split('/')
            ?.filter((it: any) => it);
          if (ref.length === 2) {
            const key = `${entry.resource.id}/${ref[0]}`;
            Promises.push(key);
            patientData[key] = entry?.resource?.patient?.display;
            consentorData[key] = entry?.resource?.consentor?.display || '';
          }
        });
      }
    });

    const params = new URLSearchParams();
    createDateParams(params, dateFilter);
    if (ok(patientFilter)) {
      params.append('subject', `Patient/${patientFilter}`);
    }

    Promises = Promises.map((item: any) =>
      Repo.fhir
        .customGet(`${useUser().getSelectedTenant()}/Consent/${item}`, params)
        .then((res: any) => {
          return {
            res: res,
            patient: patientData[item],
            consentor: consentorData[item]
          };
        })
    );

    state.consentListSharedFourUser = (
      (await Promise.allSettled(Promises).then((res: any) => {
        const array: SharedResources[] = [];
        res.forEach((it: any) => {
          if (it.status === 'fulfilled') {
            array.push({
              resources: it.value.res.data.entry,
              patient: it.value.patient,
              consentor: it.value.consentor
            });
          }
        });

        const allResourcesIds: string[] = [];
        const filteredResources: SharedResources[] = [];

        if (array.length > 0) {
          array.forEach((arr: SharedResources) => {
            if (arr.patient && arr.resources.length) {
              const allCurrentInterationIds = arr.resources.map(
                res => res.resource.id
              );
              const arrId = arr.resources[0].resource.id;

              allCurrentInterationIds.forEach(id => {
                if (id === arrId) {
                  if (!allResourcesIds.includes(arrId)) {
                    allResourcesIds.push(arrId);
                    filteredResources.push(arr);
                  }
                }
              });
            }
          });
        }

        return filteredResources;
      })) || []
    ).flatMap((m: SharedResources) =>
      m.resources.map((item: Resources) => {
        return {
          ...item,
          resource: {
            ...item.resource,
            isConsent: true
          },
          consentor: m.consentor,
          patient: m.patient
        };
      })
    );
  } else {
    useNotification().addNotification(
      NotificationType.ERROR,
      '',
      i18n.global.t('notifications.cantLoadData')
    );
  }
}

export default function useConsent() {
  const getListConsents = () =>
    state.consentList.filter(
      (item: any) => item?.resource?.resourceType === 'Consent'
    );

  const getListConsentsMappedForChips = () => {
    const list = cloneDeep(state.consentList);
    return list
      .filter((f: any) => f?.resource?.resourceType === 'Consent')
      .map((consent: any) => {
        const resource: ConsentI = consent.resource;
        return {
          documentId: `${resource.resourceType}/${resource.id}`,
          sharedFor:
            resource?.provision?.actor?.map(item => ({
              whom: item?.reference?.identifier?.value ?? '-',
              system: item?.reference?.identifier?.system ?? '-'
            })) ?? [],
          data: resource?.provision?.data?.map(item => ({
            reference: item.reference.reference
          }))
        };
      });
  };

  const getListConsentsSharedForUser = () => state.consentListSharedFourUser;

  const findConsentsRelatedToDocument = (resource: any) => {
    // const x = getListConsentsMappedForChips().map(item => ({
    //   s: item.sharedFor,
    //   d: item?.data?.map(it => it?.reference),
    //   x: `${resource.resourceType}/${resource.id}`
    // }));
    // console.log(x);

    return getListConsentsMappedForChips().filter(item =>
      item?.data?.find(
        d =>
          d?.reference?.toString() === `${resource.resourceType}/${resource.id}`
      )
    );
  };

  const actorsForDocumentConsentsOnResource = (resource: any) => {
    return findConsentsRelatedToDocument(resource)
      .map(item => item.sharedFor)
      .flat();
  };

  const isLinkSharedForPeople = (resource: any) => {
    return findConsentsRelatedToDocument(resource)
      .map(item => item.sharedFor.length === 0 && item?.data?.length !== 0)
      .some(item => item === true);
  };

  async function loadConsentById(id: string) {
    const response = await Repo.fhir.customGet(
      `${useUser().getSelectedTenant()}/Consent/${id}/Consent/${id}`
    );

    if (response?.data) {
      return response.data;
    }
    useNotification().addNotification(
      NotificationType.ERROR,
      '',
      i18n.global.t('notifications.cantLoadData')
    );

    return null;
  }

  const loadFhirResourcesByConsentId = async (id: string) => {
    const response = await loadConsentById(id);
    await loadMultipleResourcesBySettled(response);
  };

  const loadListConsents = async () => {
    const response = await Repo.fhir.customGet(
      `${useUser().getSelectedTenant()}/Consent`
    );
    if (response?.data?.entry) {
      state.consentList = (response?.data?.entry ?? []).filter(
        (f: any) => f?.resource?.status === 'active'
      );
    } else {
      useNotification().addNotification(
        NotificationType.ERROR,
        '',
        i18n.global.t('notifications.cantLoadData')
      );
    }
  };

  const loadListConsentsSharedForUser = async (
    dateFilter?: DateFilter,
    patientFilter?: string
  ) => {
    const params: URLSearchParams = new URLSearchParams();
    params.append('actor', '*');

    const response = await Repo.fhir.customGet(
      `${useUser().getSelectedTenant()}/Consent`,
      params
    );

    await loadMultipleResourcesBySettled(response, dateFilter, patientFilter);
  };

  const addConsentBy = async (
    shareType: ShareType,
    shareValue: string | number,
    resource: any
  ) => {
    const consent: ConsentI = await makeCreateConsentFromResource(
      shareType,
      shareValue,
      resource
    );

    const response = await Repo.fhir.customPut(
      `${useUser().getSelectedTenant()}/Consent`,
      consent,
      {
        code: 403,
        title: '',
        message: i18n.global.t('notifications.noSharingPermission')
      }
    );

    if (response?.data) {
      useNotification().addNotification(
        NotificationType.SUCCESS,
        '',
        i18n.global.t('base.share.shared')
      );

      await loadListConsents();
    }
  };

  const addConsentByLink = async (resource: any) => {
    const consent: ConsentI = await makeConsentByLink(resource);

    const response = await Repo.fhir.customPut(
      `${useUser().getSelectedTenant()}/Consent`,
      consent,
      {
        code: 403,
        title: '',
        message: i18n.global.t('notifications.noSharingPermission')
      }
    );

    if (response?.data) {
      useNotification().addNotification(
        NotificationType.SUCCESS,
        '',
        i18n.global.t('base.share.linkGenerated')
      );

      await loadListConsents();

      return response.data;
    }

    useNotification().addNotification(
      NotificationType.ERROR,
      '',
      i18n.global.t('base.share.errorGeneratingLink')
    );
    return null;
  };

  const disableConsent = async (resource: any) => {
    if (resource != null) {
      resource.status = 'rejected';
      await Repo.fhir.customPut(
        `${useUser().getSelectedTenant()}/Consent/${resource.id}`,
        resource
      );
    }
  };

  const removeConsentByLink = async (link: string | null) => {
    const id = link?.split('/').pop();

    if (useUser().isAdmin()) {
      await Repo.fhir.customDelete(
        `${useUser().getSelectedTenant()}/Consent/${id}`
      );
    } else {
      const { data } = await Repo.fhir.customGet(
        `${useUser().getSelectedTenant()}/Consent/${id}`
      );
      await disableConsent(data);
      console.log('consent', data);
    }
    await loadListConsents();
  };

  const removeConsentByEmailOrPhone = async (subject: any, resource: any) => {
    const consent: any = getListConsents().find(
      (item: any) =>
        item?.resource?.provision?.data?.find(
          (data: any) =>
            data.reference.reference ===
            `${resource.resourceType}/${resource.id}`
        ) &&
        item?.resource?.provision?.actor?.find(
          (data: any) =>
            data.reference.identifier.value === subject.whom &&
            data.reference.identifier.system === subject.system
        )
    );

    if (consent?.resource) {
      try {
        if (useUser().isAdmin()) {
          await Repo.fhir.customDelete(
            `${useUser().getSelectedTenant()}/Consent/${consent.resource.id}`
          );
        } else {
          await disableConsent(consent?.resource);
        }
        await loadListConsents();

        useNotification().addNotification(
          NotificationType.INFO,
          '',
          i18n.global.t('base.share.shareRemoved')
        );
      } catch (e) {
        useNotification().addNotification(
          NotificationType.ERROR,
          '',
          i18n.global.t('base.share.errorRemovingShare')
        );
        console.log(e);
      }
    }
  };

  return {
    loadFhirResourcesByConsentId,
    getListConsents,
    addConsentBy,
    getListConsentsMappedForChips,
    loadListConsents,
    addConsentByLink,
    removeConsentByLink,
    removeConsentByEmailOrPhone,
    findConsentsRelatedToDocument,
    actorsForDocumentConsentsOnResource,
    loadListConsentsSharedForUser,
    getListConsentsSharedForUser,
    loadConsentById,
    isLinkSharedForPeople
  };
}
