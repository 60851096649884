import { computed, ref, watch } from 'vue';
import { useStore as useSearchStore } from '@/store/search';
import {
  DATE,
  DATE_FILTER,
  DateFilter,
  PATIENT,
  ResourceDates,
  PATIENTS_BIRTH_DATES,
  PATIENTS_PESELS,
  PATIENTS_NAMES,
  RESULTS,
  TEXT,
  SHARED,
  Resource
} from '@/store/search/types';
import {
  FhirWrapper,
  getDescriptionFromResource
} from '@/components/Patient/PatientUtils';
import { generateUuid } from '@/utils/utils';

import { useStore as useStudyShareStore } from '@/store/studyShare';
import {
  CLEAR_SELECTED_STUDIES,
  SELECTED_STUDY
} from '@/store/studyShare/types';

import { reverse, sortBy, uniqBy } from 'lodash';
import {
  day,
  getDateFromResource,
  parseDate,
  ts,
  weekday
} from '@fhir/pixel-commons-js/src/index';
import { Filter } from '@/components/Filters/types';
import cloneDeep from 'lodash/cloneDeep';
import { InputInitValue } from '@/components/Commons/ResourcesPanel/ResourcesPanel.vue';

import { Code, System, Tag } from '@/components/Patient/PatientTags';
import useNotification from '@/composables/useNotification';
import { NotificationType } from '@/composables/types/notification';
import usePatient from '@/composables/usePatient';
import useUser from '@/composables/useUser';
import i18n from '@/lang';
import useConsent from '@/composables/useConsent';

export default () => {
  /* ---------------------------- USE ---------------------------- */
  const searchStore = useSearchStore();
  const studyShareStore = useStudyShareStore();

  /* ---------------------------- REF ---------------------------- */
  const loading = ref<boolean>(false);
  const page = ref(1);
  const perPage = ref(10);
  const isSmaller = ref<boolean>(false);
  const resources = ref<FhirWrapper[]>([]);
  const activeHeaders = ref<string[]>([]);
  const headerId = ref<string>(generateUuid());
  const shareMoreStudies = ref(false);
  const shareIdAndType = ref({ id: '', resourceType: '' });
  const readIds = ref<string[]>([]);
  const activeFocused = ref<number>(0);
  const shareDialogId = ref<string>(generateUuid());

  /* ---------------------------- COMPUTED ---------------------------- */
  const dateFilter = computed<DateFilter>(
    () => searchStore.getters[DATE_FILTER]
  );
  const patientFilter = computed<string | undefined>(
    () => searchStore.getters[PATIENT]
  );
  const sharedFilter = computed<boolean | undefined>(
    () => searchStore.getters[SHARED]
  );
  const patientsNamesFilter = computed(
    () => searchStore.getters[PATIENTS_NAMES]
  );
  const patientsPeselsFilter = computed(
    () => searchStore.getters[PATIENTS_PESELS]
  );
  const patientsBirthDatesFilter = computed(
    () => searchStore.getters[PATIENTS_BIRTH_DATES]
  );
  const admin = computed(() => useUser().isAdmin());
  const selectedTenant = computed(() => useUser().getSelectedTenant());

  const selectedDate = computed(() => searchStore.getters[DATE]);
  const selectedStudy = computed(() => studyShareStore.getters[SELECTED_STUDY]);

  const consentsSharedForUser = computed(() =>
    useConsent().getListConsentsSharedForUser()
  );

  const permissionToLoadConsents = computed(() => !useUser().isAdmin());

  const resourcesDates = computed(() => {
    const ret: Array<ResourceDates | null> = sortBy(
      uniqBy(
        resources.value
          .filter(item => item.resource)
          .map(m => {
            const date = getDateFromResource(m.resource) || null;
            if (date != null) {
              const ts = parseDate(date)?.getTime() ?? -1;

              return {
                ts,
                date: day(date),
                display: weekday(date)
              };
            }
            return null;
          })
          .filter(f => f != null),
        'date'
      ),
      'ts'
    );
    console.log(ret);
    return ret;
  });

  const filteredResources = computed(() => {
    if (sharedFilter.value) {
      return resources.value.filter(f => {
        if (f.resource.isConsent) {
          return selectedDate.value != null
            ? f.date === selectedDate.value
            : true;
        } else return false;
      });
    } else {
      return resources.value.filter(f =>
        selectedDate.value != null ? f.date === selectedDate.value : true
      );
    }
  });

  const filteredResourcesLength = computed(() => {
    if (admin.value) {
      // return length of all patients
      return filteredResources.value.filter(
        item => item.resource.resourceType === 'Patient'
      ).length;
    } else {
      // return length of all documents
      return filteredResources.value.filter(
        item => item.resource.resourceType !== 'Patient'
      ).length;
    }
  });

  const pagedResources = computed(() => {
    const start = (page.value - 1) * perPage.value;
    const stop =
      start + perPage.value < filteredResourcesLength.value
        ? start + perPage.value
        : filteredResourcesLength.value;

    // Group documents by patient
    const patients = filteredResources.value
      .filter(item => item.resource.resourceType === 'Patient')
      .map(it => ({
        patient: it,
        otherResources: filteredResources.value.filter(
          otherRes =>
            otherRes?.resource?.subject?.reference ===
            `Patient/${it.resource.id}`
        )
      }));

    const resourcesWithEarliestDate = patients.map(patient => {
      const otherResources = [...patient.otherResources];
      let earliestDate;
      if (otherResources.length > 1) {
        earliestDate = otherResources.reduce((a: any, b: any) => {
          const firstDate = a.date ? a.date : a;
          const secondDate = b.date ? b.date : b;
          return firstDate > secondDate ? firstDate : secondDate;
        });
      } else if (otherResources.length === 1) {
        earliestDate =
          otherResources[0].date === '-' ? '0' : otherResources[0].date;
      }
      return { earliestDate, ...patient };
    });
    const compareNumbers = (a: any, b: any) => {
      if (a.earliestDate < b.earliestDate) return 1;
      if (a.earliestDate > b.earliestDate) return -1;
      return 0;
    };
    const sorted = resourcesWithEarliestDate.sort(compareNumbers);
    return sorted.slice(start, stop);
  });

  const pagedResourcesOnlyDocuments = computed(() => {
    let docs: any = [];

    const start = (page.value - 1) * perPage.value;
    const stop =
      start + perPage.value < filteredResourcesLength.value
        ? start + perPage.value
        : filteredResourcesLength.value;

    if (permissionToLoadConsents.value) {
      // Show not grouped elements
      // load by patient / other user than admin
      docs = filteredResources.value.map(it => ({
        patient: it,
        otherResources: filteredResources.value.filter(
          otherRes => otherRes.resource.resourceType !== 'Patient'
        )
      }));
    } else {
      // Show grouped elements
      docs = filteredResources.value
        .filter(item => item.resource.resourceType === 'Patient')
        .map(it => ({
          patient: it,
          otherResources: filteredResources.value.filter(
            otherRes => otherRes.resource.resourceType !== 'Patient'
          )
        }));
    }

    // if date in availableDocs is selected return this resource
    if (selectedDate.value) {
      return filteredResources.value;
    }

    if (docs && Array.isArray(docs) && docs.length !== 0) {
      return docs[0].otherResources.slice(start, stop);
    } else {
      return [];
    }
  });

  const allPatients = computed(() =>
    filteredResources.value
      .filter(item => item.resource.resourceType === 'Patient')
      .map(it => ({ ...it }))
  );

  const usedFilters = computed<Filter[]>(() => {
    const ret: Filter[] = [];
    if (selectedDate.value != null) {
      ret.push({
        description: i18n.global.t('patient.filters.documentData', {
          date: selectedDate.value
        }),
        remove: () => searchStore.commit(DATE, null)
      });
    } else if (
      dateFilter.value != null &&
      (dateFilter.value.date != null ||
        (dateFilter.value.dateFrom != null && dateFilter.value.dateTo != null))
    ) {
      const filter = {
        description: i18n.global.t('patient.filters.documentData', {
          date:
            dateFilter.value.date != null
              ? dateFilter.value.date
              : `${dateFilter.value.dateFrom} - ${dateFilter.value.dateTo}`
        }),
        remove: () => searchStore.commit(DATE_FILTER, {})
      };
      ret.push(filter);
    }

    if (patientFilter.value != null) {
      const filter = {
        description: i18n.global.t('patient.filters.documentPatient', {
          patient:
            usePatient().getPatientById(patientFilter.value)?.name ||
            patientFilter.value
        }),
        remove: () => searchStore.commit(PATIENT, null)
      };
      ret.push(filter);
    }

    if (patientsNamesFilter.value.length > 0) {
      const filter = {
        patientsNames: patientsNamesFilter.value,
        description: i18n.global.t('admin.filters.patients'),
        remove: () => searchStore.commit(PATIENTS_NAMES, 'clear')
      };
      ret.push(filter);
    }

    if (sharedFilter.value) {
      const filter = {
        shared: sharedFilter.value,
        description: i18n.global.t('patient.filters.sharedToMe'),
        remove: () => searchStore.commit(SHARED, false)
      };
      ret.push(filter);
    }

    if (patientsBirthDatesFilter.value.length > 0) {
      const filter = {
        birthDates: patientsBirthDatesFilter.value,
        description: i18n.global.t('admin.filters.birthDates'),
        remove: () => searchStore.commit(PATIENTS_BIRTH_DATES, 'clear')
      };
      ret.push(filter);
    }

    if (patientsPeselsFilter.value.length > 0) {
      const filter = {
        pesels: patientsPeselsFilter.value,
        description: i18n.global.t('admin.filters.pesel'),
        remove: () => searchStore.commit(PATIENTS_PESELS, 'clear')
      };
      ret.push(filter);
    }

    return ret;
  });

  const getMoreResourcesToShare = computed(() => {
    const resources = admin.value
      ? cloneDeep(pagedResources.value)
          .map(it => [...it.otherResources])
          .flat()
      : pagedResourcesOnlyDocuments.value;

    const onlyIds = resources.map((item: Resource) => item.resource.id);

    const selectedIds = onlyIds.filter(
      (id: string) => selectedStudy.value.indexOf(id) !== -1
    );

    const selectedResources = resources.filter((item: Resource) =>
      selectedIds.find((id: string) => id === item.resource.id)
    );

    return selectedResources.map((item: Resource) => item.resource);
  });

  const handleOneResource = computed(() => {
    const sharingId = shareIdAndType.value.id;
    const sharingType = shareIdAndType.value.resourceType;

    const resources = admin.value
      ? pagedResources.value.map(it => [...it.otherResources]).flat()
      : pagedResourcesOnlyDocuments.value;

    const found = resources.find((item: Resource) => {
      const type = item.resource.resourceType;
      const id = item.resource.id;
      return type === sharingType && id === sharingId;
    });

    return found ? found.resource : {};
  });

  /* ----------------------------  METHODS ---------------------------- */
  const checkIfHeaderCanBeBolded = (id: string | null | undefined) => {
    if (id) {
      // If study is NOT read - bold header (only in patient mode)
      return readIds.value.indexOf(id.toString()) !== -1
        ? ''
        : admin.value
        ? ''
        : 'font-bold';
    } else {
      return '';
    }
  };

  const handleGetCheckbox = (e: InputInitValue) => {
    studyShareStore.commit(SELECTED_STUDY, e.id);
  };

  const getActiveHeader = (e: any) => {
    pagedResources.value.map((x: any, ind: number) => {
      if (
        x.patient.resource.id === e.parentResource.id &&
        x.patient.resource.resourceType === e.parentResource.resourceType
      ) {
        const header =
          e.header.trim().length !== 0
            ? e.header
            : `[ ${i18n.global.t('patient.noInformation')} ]`;
        if (e.add) {
          activeHeaders.value[ind] = header;
        } else {
          activeHeaders.value[ind] = header;
        }
      }
    });
  };

  const handleCloseShareModal = () => {
    shareIdAndType.value.id = '';
    shareIdAndType.value.resourceType = '';
    shareMoreStudies.value = false;
  };

  const setActiveFocused = (i: number): void => {
    activeFocused.value = i;
  };

  const getterSearchText = computed(() => searchStore.getters[TEXT]);

  const showPatients = computed(() => usePatient().getPatients().length > 1);

  const search = async () => {
    try {
      if (permissionToLoadConsents.value) {
        await useConsent().loadListConsentsSharedForUser(
          dateFilter.value,
          patientFilter.value
        );
      }

      const tempResourcesLength: number = resources.value.length;
      activeHeaders.value = [];
      resources.value = [];
      page.value = 1;
      loading.value = true;

      const data = await usePatient().searchPatients(
        dateFilter.value,
        patientFilter.value,
        selectedTenant.value,
        getterSearchText.value
      );
      if (
        data?.data?.entry?.length !== 0 ||
        consentsSharedForUser.value.length !== 0
      ) {
        let r: any[] = data?.data?.entry ?? [];

        if (consentsSharedForUser.value.length !== 0) {
          r = [...r, ...consentsSharedForUser.value];
        }

        if (r.length !== tempResourcesLength) {
          page.value = 1;
        }

        resources.value = uniqBy(
          reverse(
            sortBy(
              r
                .map(m => {
                  const date = getDateFromResource(m.resource);
                  const desc = getDescriptionFromResource(m.resource) || '';
                  const patient = showPatients.value
                    ? usePatient()
                        .matchPatientById(m.resource?.subject?.reference)
                        ?.toString()
                    : null;
                  return {
                    id: m.resource.id,
                    uuid: generateUuid(),
                    ts: Number(ts(date)),
                    date: day(date),
                    description: desc,
                    resource: m.resource,
                    consentor: m.consentor,
                    doctor: m.resource.resourceType,
                    patient: m.patient || patient
                  };
                })
                .filter(item => item.resource),
              'ts'
            )
          ),
          'id'
        );

        readIds.value = resources.value
          .filter(it =>
            it.resource?.meta?.tag?.find(
              (tag: Tag) =>
                tag.system === System.patientPortalSystem &&
                tag.code === Code.displayedResource
            )
          )
          .map(it => it.resource.id);
      }
    } catch (e) {
      useNotification().addNotification(
        NotificationType.ERROR,
        '',
        i18n.global.t('patient.results.error')
      );
      console.error(i18n.global.t('patient.results.error'), e);
    } finally {
      loading.value = false;
    }
  };

  /* ---------------------------- MOUNTED ---------------------------- */
  // onMounted(async () => {
  //   // TODO: SAVE INTO DB
  //   const { data } = await PatientRepo.listOfDisplayedStudies('USER ID');
  //   readIds.value = data.map((it: any) => it.id);
  // });

  /* ---------------------------- WATCH -------------------------------- */
  watch(
    resourcesDates,
    v => {
      Promise.resolve().then(() => searchStore.commit(RESULTS, v));
    },
    {
      immediate: true
    }
  );

  watch(
    page,
    () => {
      activeHeaders.value = [];
    },
    { immediate: true }
  );

  watch(
    selectedDate,
    (newValue, prevValue) => {
      page.value = 1;
      if (!newValue || prevValue !== newValue)
        studyShareStore.commit(CLEAR_SELECTED_STUDIES);
    },
    { immediate: true }
  );
  // TODO
  watch(
    allPatients,
    value => {
      if (value && Array.isArray(value) && value.length !== 0) {
        const tenant = useUser().getSelectedTenant();
        usePatient().loadPatient(tenant);
        // usePatient().userStore.commit(
        //   PATIENT_BIRTHDATE,
        //   value[0].resource?.birthDate
        // );
        // userStore.commit(PATIENT_PESEL, pesel(value[0].resource));
        // userStore.commit(
        //   PATIENT_NAME,
        //   getPatientNameNameAndSurname(value[0].resource)
        // );
        // userStore.commit(PATIENT_AGE, getPatientAge(value[0].resource));
      }
    },
    { immediate: true }
  );

  return {
    loading,
    admin,
    dateFilter,
    patientFilter,
    search,
    resources,
    filteredResourcesLength,
    page,
    perPage,
    filteredResources,
    pagedResources,
    usedFilters,
    handleGetCheckbox,
    selectedStudy,
    shareIdAndType,
    getMoreResourcesToShare,
    handleCloseShareModal,
    shareMoreStudies,
    getActiveHeader,
    activeHeaders,
    sharedFilter,
    readIds,
    activeFocused,
    shareDialogId,
    setActiveFocused,
    isSmaller,
    headerId,
    handleOneResource,
    checkIfHeaderCanBeBolded,
    pagedResourcesOnlyDocuments,
    selectedTenant,
    getterSearchText
  };
};
